<template>
  <div class="setBox">
    <div class="boxTop">
      <div class="topLeft" @click="goBack()"></div>
      <div class="topCon">黑名单</div>
    </div>
    <div class="contentBox">
      <div class="container" v-for="item in blackList" :key="item.id">
        <div class="contentHear"><img :src="item.blackHeadImg" alt=""></div>
        <div class="contentNameSize">
          <div class="nameDiv">{{item.blackNickName}}</div>
          <div class="sizeDiv">昔者庄周梦为胡蝶，栩栩然胡蝶也。自喻...</div>
        </div>
        <div class="delBtn" @click="btnEvent()">移除</div>
      </div>
    </div>
    <van-overlay :show="show" @click="show = false">
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="blockTop">将“低调的先生”移除黑名单</div>
          <div class="blockContent">
            移除后对方将可以关注你、评论或点赞你的圈子、向你发私信等。
          </div>
          <div class="blockBtn">
            <div @click="show = false">取消</div>
            <div @click="removeEvent()">确定</div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { blackPage } from "../../../api/circle";
export default {
  data() {
    return {
      show: false,
      blackList:[]
    };
  },
  mounted() {
    this.getblackPage();
  },
  methods: {
    getblackPage() {
      blackPage({
        pageNum: 1,
        pageSize: 10,
      }).then((res) => {
        console.log(res, "黑名单");
        this.blackList = res.data.data.items
      });
    },
    goBack() {
      this.$router.push({ path: "/privacyPage" });
    },
    // 移除弹框
    btnEvent() {
      this.show = true;
    },
    // 确定移除
    removeEvent() {
      this.show = false;
    },
  },
};
</script>

<style scoped>
.boxTop {
  width: 750px;
  height: 88px;
  display: flex;
  align-items: center;
  margin-bottom: 22px;
  position: fixed;
  top: 0px;
  background-color: #fff;
}
.topLeft {
  width: 48px;
  height: 48px;
  background-image: url("../../../image/back.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.topCon {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
  margin-left: 248px;
}
.contentBox {
  margin-top: 90px;
}
.container {
  width: 686px;
  height: 144px;
  border-bottom: 1px solid #f6f8fa;
  margin-left: 32px;
  display: flex;
  align-items: center;
}
.contentHear img{
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.contentNameSize {
  margin-left: 20px;
}
.nameDiv {
  font-size: 28px;
  font-weight: 600;
  color: #0b1526;
}
.sizeDiv {
  width: 454px;
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  margin-top: 6px;
  display: -webkit-box; /* Safari */
  -webkit-line-clamp: 1; /* Safari and Chrome */
  -webkit-box-orient: vertical; /* Safari and Chrome */
  overflow: hidden;
}
.delBtn {
  width: 120px;
  height: 56px;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 2px solid #fa2819;
  font-size: 24px;
  font-weight: 400;
  color: #fa2819;
  text-align: center;
  line-height: 60px;
  margin-left: 12px;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 510px;
  height: 346px;
  background: #ffffff;
  border-radius: 24px 24px 24px 24px;
  opacity: 1;
}
.blockTop {
  width: 510px;
  height: 88px;
  border-bottom: 1px solid #f6f8fa;
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
  text-align: center;
  line-height: 88px;
}
.blockContent {
  font-size: 28px;
  width: 446px;
  font-weight: 400;
  color: #0b1526;
  margin-left: 32px;
  margin-top: 20px;
}
.blockBtn {
  display: flex;
  margin-top: 32px;
}
.blockBtn div:nth-child(1) {
  width: 160px;
  height: 84px;
  background: #f6f8fa;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  font-size: 32px;
  font-weight: 400;
  color: #0b1526;
  text-align: center;
  line-height: 84px;
  margin-left: 32px;
}
.blockBtn div:nth-child(2) {
  width: 262px;
  height: 84px;
  background: #0957c3;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  font-size: 32px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 84px;
  margin-left: 24px;
}
</style>
